<script>
export default {
	lang: 'shop',
	props: ['order', 'summary'],
	computed: {
		summaryResultsLines() {
			return this.summary.getResultsLines({
				items: {
					text: this.$lang('Productos'),
					divider: true,
					valueClass: 'grey--text text--darken-2',
				},
				itemsBaseDiscount: {
					name: this.$lang('Descuento especial'),
					listItemClass: 'discount--text',
					valueClass: 'discount--text',
				},
				discounts: {
					listItemClass: 'discount--text',
					valueClass: 'discount--text',
				},
				subtotal: {
					text: this.$lang('Subtotal'),
					fontClass: 'font-3',
					listItemClass: 'taller',
					divider: true,
				},
				total: {
					text: this.$lang('Total a pagar'),
					fontClass: 'font-3 font-sm-4',
					divider: true,
					listItemClass: 'taller',
					valueClass: 'font-weight-bold',
				},
				showSubtotal: true,
			})
		},
	},
	cssVars: {
		selector: '.cart-drawer__order-summary-totals',
	},
}
</script>

<template>
	<div class="cart-drawer__order-summary-totals base">
		<template v-for="(line, i) of summaryResultsLines">
			<v-divider :key="`d${i}`" v-if="line.divider" />
			<v-list-item
				:style="`color: ${$vars.basetext}`"
				:key="`li${i}`"
				:class="line.listItemClass"
				class="align-center"
			>
				<div :class="[line.fontClass || `font-1`, line.textClass]">
					{{ line.text }}
				</div>
				<v-spacer />
				<div :class="[line.fontClass || `font-2`, line.valueClass]">
					<PriceText :amount="line.value" :currency="summary.currency" :priceClass="line.valueClass" />
				</div>
			</v-list-item>
		</template>
	</div>
</template>

<style scoped>
.v-list-item {
	min-height: 36px;
}
.v-list-item.taller {
	min-height: 48px;
}
</style>
