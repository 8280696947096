<script>
export default {
	lang: 'shop',
	props: ['order', 'joinedItem'],
	computed: {
		currency() {
			return this.order.currency.sign
		},
		item() {
			return this.joinedItem.items[0]
		},
	},
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	hooksKey: 'OrderSummaryItem',
}
</script>

<template>
	<div class="d-flex align-start px-2 py-4 px-sm-2">
		<div class="item-image-cont flex-shrink-0">
			<Media
				:src="item.image"
				aspect-ratio="1"
				width="100%"
				img-class="rounded"
				class="rounded item-image"
			/>
		</div>
		<div class="flex-shrink-1 px-2 px-sm-4">
			<hook zone="name">
				<div class="font-weight-bold line-clamp-2 font-1 font-sm-2">
					{{ item.name }}
				</div>
			</hook>

			<div v-if="item.descriptions">
				{{ item.descriptions.map((e) => e.text).join(' | ') }}
			</div>
			<div class="grey--text font-0 mt-1">SKU {{ item.sku }}</div>
			<div class="mt-2">
				<div v-for="(group, i) of joinedItem.finalPricesGroups" :key="i" class="d-flex align-center py-1">
					<div class="grey--text font-1 pr-2" v-if="joinedItem.finalPricesGroups.length > 1">
						{{ group.qty }}x
					</div>
					<ProductPrice
						:amount="group.finalPrice"
						:currency="currency"
						custom-class="black--text font-2"
					/>
					<ProductPrevPrice
						v-if="group.initPrice > group.finalPrice"
						:amount="group.initPrice"
						:currency="currency"
						customClass="font-0 pl-2 grey--text"
					/>
				</div>
			</div>

			<!-- <div class="text-body-2 pt-1" v-if="item.discount">
				<span>{{ item.discountsNames.map((e) => e.text).join(' + ') }}</span>
				<ProductDiscount :amount="item.discountPct" class="ml-1" />
			</div> -->
		</div>
		<v-spacer />
		<div>
			<div class="text-right">
				<PriceText
					class="font-weight-bold font-2 font-sm-3 d-inline"
					:amount="joinedItem.finalTotal"
					:currency="currency"
				/>
			</div>
			<div class="text-caption nowrap text-right">
				{{ joinedItem.totalQty }} {{ joinedItem.unitMetricWord }}
			</div>
			<div class="text-caption nowrap text-right grey--text" v-if="joinedItem.packMetricWord">
				{{ joinedItem.totalPackQty }} {{ joinedItem.packMetricWord }}
			</div>
			<div class="d-flex justify-end mt-2" v-if="joinedItem.finalDiscountTotal">
				<div class="lighten-5 pt-1 px-2 text-center">
					<div class="discount--text font-0 font-weight-bold d-flex align-center">
						<v-icon small color="discount" class="mr-1">mdi-ticket-percent</v-icon>
						<span>{{ 'Ahorro' | lang }}</span>
					</div>
					<PriceText
						class="discount--text font-weight-bold font-0 text-end"
						:amount="Math.floor(joinedItem.finalDiscountTotal)"
						:currency="currency"
						priceClass="discount--text"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.item-image {
	border: 1px solid #ddd;
}
.item-image-cont {
	width: 70px;
}
</style>
